.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
flex-direction: column;
border-bottom: solid 1px #eee;
gap: 8px;
margin-bottom: 0;
padding-bottom: 10px;
}