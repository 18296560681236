@import '../../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

border: solid 1px #eee;
outline: 0;
min-width: 0;
text-overflow: ellipsis;
padding-left: 8px;
font-size: 12px;
width: 100%;
height: 32px;
}