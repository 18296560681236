.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: inline-flex;
align-items: center;
gap: 8px;
&.disabled {
opacity: 0.33;
}
}