@import '../../../styles/mixins.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include thin-scrollbar(#d8d8d8);
max-height: 320px;
overflow: auto;
width: 100%;
height: 100%;
margin-top: 10px;
margin-bottom: 10px;
}