$large-desktop-width: 1440px;

$color-blue: #008ac6;
$color-light-blue: #51c2f6;
$color-black: #212121;
$color-red: #ff4256;
$color-gray: #f5f5f5;
$color-dark-gray: #9d9d9d;
$color-warm-gray: #979797;
$color-light-gray: #D8D8D8;
$color-text-gray: rgba(0, 0, 0, 0.87);
$color-white: #fff;
$color-cold-red: #f6c5c5;

$color-level-1: white;
$color-level-2: #f4fbff;
$color-level-3: #caecfc;

$z-index-popup: 100;
$z-index-modal: 10000;
$z-index-select: 10001;
$z-index-tooltip: 10002;
$z-index-toastify: 15000 !important;
