@import '../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

background-color: rgba(255, 255, 255, 0.85);
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
z-index: $z-index-modal;
box-sizing: border-box;
-webkit-overflow-scrolling: touch;
}