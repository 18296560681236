@import '../../../../static/styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

line-height: 32px;
min-height: 32px;
padding: 0 11px;
font-size: 12px;
background: white;
color: $color-black;
border: solid 1px transparent;
outline: 0;
transition: 0.5s;

&.invalid {
border: solid 1px $color-red;
}

&.border {
border: solid 1px #ededed;
}
}