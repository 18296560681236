.cls1 { /*!*/ }
.cls2 {
composes: cls1;

min-height: 100%;
min-width: 100%;
display: inline-flex;
justify-content: center;
align-items: flex-start;
top: -4px;
position: relative;
}