@import '../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

display: flex;
align-items: center;
padding: 4px 4px;
margin-left: -4px;
fill: $color-blue;
&.disabled {
fill: $color-light-gray;
}
}